import { FC, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { FlexDiv } from "components/Layout/FlexDiv";
import { ProspectInfoWrapper } from "./styles";
import { ChipInput } from "components/ChipInput";
import { UserInfoProps } from "./type";
import { ActiveSwitch } from "../SubscriptionInfo/styles";
import { useConfirmModal } from "context/confirmModal";
import useDeleteUser from "hooks/services/user/useDeleteUser";
import { useConditionalSnackBar } from "context/snackbar";
import { CronoButton } from "components/CronoButton";
import { User } from "types/user";
import useResetPassword from "hooks/services/subscription/useResetPassword";
import Role from "types/enums/role";
import usePatchUser from "hooks/services/user/usePatchUser";
import { useAuth } from "context/auth";
import { getError } from "utils";
import { OpenInNewOutlined } from "@mui/icons-material";

const UserInfo: FC<UserInfoProps> = ({ user, subscription }) => {
  const { admin } = useAuth();
  const [firstName, setFirstName] = useState<string>(user?.firstName || "");
  const [lastName, setLastName] = useState<string>(user?.lastName || "");
  const [email, setEmail] = useState<string>(user?.email || "");
  const [active, setActive] = useState<boolean>(user?.active || false);
  const [isManager, setIsManager] = useState<boolean>(
    user.userRoles.includes(Role.MANAGER) ?? false
  );
  const [isSubscriptionManager, setIsSubscriptionManager] = useState<boolean>(
    user.userRoles.includes(Role.SUBSCRIPTION_MANAGER) ?? false
  );

  const { openModal: openConfirmModal } = useConfirmModal();

  const {
    mutate: patchUser,
    isSuccess: isPatchSuccess,
    error: patchError,
  } = usePatchUser();

  const {
    mutate: deleteUser,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
  } = useDeleteUser();

  const {
    mutate: resetPassword,
    isSuccess: isResetSuccess,
    isError: isResetError,
  } = useResetPassword();

  useEffect(() => {
    setFirstName(user?.firstName || "");
    setLastName(user?.lastName || "");
    setEmail(user?.email || "");
    setActive(user?.active || false);
  }, [user]);

  const handleActiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextStatus = e.target.checked;
    openConfirmModal({
      title: "Are you sure you want to disable the following user?",
      text: "User: " + user?.email + " will be disabled",
      cancelText: "No",
      confirmText: "Yes",
      confirmFunction: () => {
        if (user && subscription && user.active) {
          openConfirmModal({
            title: "Delete permanently?",
            text: "Data for: " + user?.email + " will be deleted forever",
            cancelText: "No",
            confirmText: "Yes",
            confirmFunction: () => {
              setActive(nextStatus);
              deleteUser({
                delete: true,
                userId: user.id,
                subscriptionId: subscription?.id,
              });
            },
            cancelFunction: () => {
              setActive(nextStatus);
              deleteUser({
                delete: false,
                userId: user.id,
                subscriptionId: subscription?.id,
              });
            },
          });
        }
      },
      cancelFunction: () => null,
    });
  };

  const handleChangeIsManager = () => {
    setIsManager(!isManager);
    patchUser({
      manager: !isManager,
      userId: user.id,
      subscriptionId: subscription?.id,
    });
  };

  const handleChangeIsSubscriptionManager = () => {
    setIsSubscriptionManager(!isSubscriptionManager);
    patchUser({
      subscriptionManager: !isSubscriptionManager,
      userId: user.id,
      subscriptionId: subscription?.id,
    });
  };

  const handleChangeFirstName = () => {
    patchUser({
      firstName: firstName,
      userId: user.id,
      subscriptionId: subscription?.id,
    });
  };

  const handleChangeLastName = () => {
    patchUser({
      lastName: lastName,
      userId: user.id,
      subscriptionId: subscription?.id,
    });
  };
  const handleChangeEmail = () => {
    patchUser({
      email: email,
      userId: user.id,
      subscriptionId: subscription?.id,
    });
  };

  useConditionalSnackBar([
    {
      condition: !!isDeleteError,
      message: "Error while disabling the user",
      severity: "error",
    },
    {
      condition: !!isDeleteSuccess,
      message: "User disabled successfully",
      severity: "success",
    },
    {
      condition: !!patchError,
      message: getError(patchError) ?? "Error while updating user information",
      severity: "error",
    },
    {
      condition: !!isPatchSuccess,
      message: "User information updated successfully",
      severity: "success",
    },
    {
      condition: !!isResetError,
      message: "Error while resetting the password",
      severity: "error",
    },
    {
      condition: !!isResetSuccess,
      message: "Password reset successfully",
      severity: "success",
    },
  ]);

  if (!user) {
    return (
      <FlexDiv>
        <CircularProgress />
      </FlexDiv>
    );
  }

  const resetPasswordRequest = (user: User) => {
    openConfirmModal({
      title: "Are you sure you want to reset the password?",
      text: "A new password will be sent to: " + user?.email,
      cancelText: "No",
      confirmText: "Yes",
      confirmFunction: () => {
        if (user && subscription) {
          resetPassword({
            userId: user.id,
            subscriptionId: subscription?.id,
          });
        }
      },
      cancelFunction: () => null,
    });
  };

  return (
    <ProspectInfoWrapper>
      <div className="prospect-info-row">
        <p>Active</p>
        <ActiveSwitch
          id="active"
          name="active"
          checked={active}
          onChange={user?.active ? handleActiveChange : () => null}
        />
      </div>
      <div className="prospect-info-row">
        <p>First name</p>
        <ChipInput
          className="prospect-info-chip"
          textAlign={"center"}
          id="firstName"
          name="firstName"
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              //The onBlur will call the API
              event.target.blur();
            }
          }}
          onBlur={handleChangeFirstName}
          disabled={!admin?.canModify}
        />
      </div>
      <div className="prospect-info-row">
        <p>Last name</p>
        <ChipInput
          className="prospect-info-chip"
          textAlign={"center"}
          id="lastName"
          name="lastName"
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              //The onBlur will call the API
              event.target.blur();
            }
          }}
          onBlur={handleChangeLastName}
          disabled={!admin?.canModify}
        />
      </div>
      <div className="prospect-info-row">
        <p>E-Mail</p>
        <FlexDiv>
          <ChipInput
            className="prospect-info-chip"
            textAlign={"center"}
            id="email"
            name="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                //The onBlur will call the API
                event.target.blur();
              }
            }}
            onBlur={handleChangeEmail}
            disabled={!admin?.canModify}
          />
        </FlexDiv>
      </div>
      <div className="prospect-info-row">
        <p>User Id</p>
        <FlexDiv>
          <ChipInput
            className="prospect-info-chip"
            textAlign={"center"}
            id="id"
            name="id"
            value={user.id}
          />
        </FlexDiv>
      </div>
      <div className="prospect-info-row">
        <p>Integration</p>
        <FlexDiv>
          <ChipInput
            className="prospect-info-chip"
            textAlign={"center"}
            id="integration"
            name="integration"
            value={
              user.firstIntegration
                ? "First import done"
                : user.integrationType
                ? "Integrated "
                : "Not integrated"
            }
          />
        </FlexDiv>
      </div>
      <div className="prospect-info-row">
        <p>Last Login</p>
        <FlexDiv>
          <ChipInput
            className="prospect-info-chip"
            textAlign={"center"}
            id="lastLogin"
            name="lastLogin"
            value={
              user.lastLogin ? user.lastLogin.toString().split("T")[0] : "Never"
            }
          />
        </FlexDiv>
      </div>
      <div className="prospect-info-row">
        <p>Linkedin Profile</p>
        <FlexDiv>
          <ChipInput
            className="prospect-info-chip"
            textAlign={"center"}
            id="linkedin"
            name="linkedin"
            value={
              user.linkedinSettings
                ? user.linkedinSettings?.publicId
                : "Not connected"
            }
            disabled={true}
          />
          {user.linkedinSettings?.publicId && (
            <OpenInNewOutlined
              onClick={() => {
                if (user.linkedinSettings) {
                  window.open(
                    "https://www.linkedin.com/in/" +
                      user.linkedinSettings?.publicId,
                    "_blank"
                  );
                }
              }}
              style={{
                cursor: "pointer",
              }}
            />
          )}
        </FlexDiv>
      </div>
      {/* <div className="prospect-info-row">
        <p>Roles</p>
        <FlexDiv>
          <ChipInput
            className="prospect-info-chip"
            textAlign={"center"}
            id="roles"
            name="roles"
            value={user.userRoles.map((role) => role).join(", ")}
          />
        </FlexDiv>
      </div> */}
      <div className="prospect-info-row">
        <p>Manager</p>
        <ActiveSwitch
          id="isManager"
          name="isManager"
          checked={isManager}
          onChange={() => handleChangeIsManager()}
        />
      </div>
      <div className="prospect-info-row">
        <p>Subscription Manager</p>
        <ActiveSwitch
          id="isSubscriptionManager"
          name="isSubscriptionManager"
          checked={isSubscriptionManager}
          onChange={() => handleChangeIsSubscriptionManager()}
        />
      </div>
      <div className="prospect-info-row">
        <p>Password</p>
        <FlexDiv>
          <CronoButton
            variant="contained"
            color="primary"
            className="mail-button"
            onClick={() => resetPasswordRequest(user)}
          >
            Reset
          </CronoButton>
        </FlexDiv>
      </div>
    </ProspectInfoWrapper>
  );
};

export default UserInfo;
