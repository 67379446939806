import React, { SetStateAction } from "react";
import IntegrationType from "types/enums/integrationType";
import SubscriptionType from "types/enums/subscriptionType";
import { UserInsert } from "types/userInsert";
import { Constants } from "utils/constants/constants";
import * as Yup from "yup";

export const validationSchema = Yup.object({
  companyName: Yup.string().required("Company name is required"),
  companyWebsite: Yup.string().test(
    "is-valid-website",
    "Invalid website",
    (value) => {
      if (!value) return false;
      const regex = Constants.websiteRegex;
      return regex.test(value);
    }
  ),
  companyLinkedinUrl: Yup.string()
    .nullable()
    .test("is-valid-linkedin-url", "Invalid Linkedin URL", (value) => {
      if (!value) return true;
      const regex = Constants.linkedinUrlRegex;
      return regex.test(value);
    }),
  basicUserQuantity: Yup.number()
    .min(1, "Must be at least 1 user")
    .max(25, "Must be at most 25 users")
    .required("Basic user quantity is required"),
  managerUserQuantity: Yup.number()
    .min(1, "Must be at least 1 manager")
    .max(5, "Must be at most 5 managers")
    .required("Manager user quantity is required"),
  subscriptionType: Yup.string()
    .oneOf(
      [
        SubscriptionType.STARTER,
        SubscriptionType.PRO,
        SubscriptionType.ULTRA,
        SubscriptionType.FREE_TRIAL,
      ],
      "Invalid Subscription type"
    )
    .required("Subscription type is required"),
  integrationType: Yup.string()
    .oneOf(
      ["hubspot", "salesforce", "crono", "pipedrive"],
      "Invalid integration type"
    )
    .required("Integration type is required"),
  callIntegrationType: Yup.string().oneOf(
    ["Aircall", "None"],
    "Invalid call integration type"
  ),
  expirationDate: Yup.date()
    .min(
      new Date(Date.now() + 1000 * 60 * 60 * 24 * 6),
      "Must be at least 7 days from now"
    )
    .max(
      new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 6),
      "Must be at most 6 years from now"
    )
    .required("Expiration date is required"),
  // subscriptionType: Yup.string().required("Subscription type is required"),
  // otherSettings
  currency: Yup.string()
    .oneOf(["$", "€"], "Invalid currency")
    .required("Currency is required"),
  hasLinkedin: Yup.boolean(),
  hasEmail: Yup.boolean(),
  hasCall: Yup.boolean(),
  hasLastFeatures: Yup.boolean(),
  hasLateSync: Yup.boolean(),
  monthScrapeRequest: Yup.number()
    .min(10, "Must be at least 10 requests")
    .max(
      Constants.maxMonthScrapeRequest,
      "Must be at most " +
        Constants.maxMonthScrapeRequest.toString() +
        " requests"
    ),
  monthVerifyRequest: Yup.number()
    .min(10, "Must be at least 10 requests")
    .max(
      Constants.maxMonthVerifyRequest,
      "Must be at most " +
        Constants.maxMonthVerifyRequest.toString() +
        " requests"
    ),
  monthFindPhoneRequest: Yup.number()
    .min(0, "Must be at least 0 requests")
    .max(
      Constants.maxMonthScrapeRequest,
      "Must be at most " +
        Constants.maxMonthScrapeRequest.toString() +
        " requests"
    ),
  monthGenerateTemplateRequest: Yup.number()
    .min(10, "Must be at least 10 requests")
    .max(
      Constants.maxMonthGenerateTemplateRequest,
      "Must be at most " +
        Constants.maxMonthGenerateTemplateRequest.toString() +
        " requests"
    ),
  // string list
  subscriptionDomains: Yup.array()
    .of(Yup.string().matches(Constants.domainRegex))
    .nullable(),
  priceId: Yup.string().nullable(),
  coupon: Yup.string().nullable(),
});

export interface SubscriptionInputs {
  companyName: string;
  companyWebsite: string | null;
  companyLinkedinUrl: string | null;
  basicUserQuantity: number;
  managerUserQuantity: number;
  subscriptionType: SubscriptionType;
  integrationType: IntegrationType;
  callIntegrationType: string;
  expirationDate: Date;
  currency: string;
  hasLinkedin: boolean;
  hasEmail: boolean;
  hasCall: boolean;
  hasLateSync: boolean;
  monthScrapeRequest: number;
  monthVerifyRequest: number;
  monthFindPhoneRequest: number;
  hasLastFeatures: boolean;
  monthGenerateTemplateRequest: number;
  users: UserInsert[];
  multiCustomer: boolean;
  subscriptionDomains: string[] | null;
  priceId: string | null;
  coupon: string | null;
}

export const initialValues = {
  companyName: "",
  companyWebsite: null,
  companyLinkedinUrl: null,
  basicUserQuantity: 1,
  managerUserQuantity: 1,
  subscriptionType: SubscriptionType.STARTER,
  integrationType: IntegrationType.HUBSPOT,
  callIntegrationType: "None",
  expirationDate: new Date(),
  currency: "€",
  hasLinkedin: true,
  hasEmail: true,
  hasCall: true,
  hasLateSync: false,
  monthScrapeRequest: 10,
  monthVerifyRequest: 1000,
  monthFindPhoneRequest: 0,
  hasLastFeatures: false,
  monthGenerateTemplateRequest: 10,
  multiCustomer: false,
  subscriptionDomains: null,
  priceId: null,
  coupon: null,
  users: [],
};

export const userInitialValues = {
  email: "",
  firstName: "",
  lastName: "",
  userRoles: ["Basic"],
};

export const userValidationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  userRoles: Yup.array()
    .min(1)
    .of(Yup.string().oneOf(["Basic", "Manager"])),
});

export interface InsertSubscriptionProps {
  setOpen: React.Dispatch<SetStateAction<boolean>>;
}
